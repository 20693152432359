import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    section as sectionClass,
    grid,
    titleText,
    subtitleText,
    contentText,
    image,
    content,
} from './article-preview.module.scss';

import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import Markdown from '../hoc/markdown';

interface IArticleProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

const ArticlePreview: React.FC<IArticleProps> = ({ className = '', section }) => {
    const {
        sectionId,
        content: { texts, media },
        css,
        style,
    } = section;
    const [title, subtitle, description] = texts;

    const hasMedia = media.length > 0;

    return (
        <Section
            sectionId={sectionId}
            className={[sectionClass, className].join(' ')}
            classes={{
                container: grid,
            }}
            css={css}
            style={style}
        >
            <div className={content}>
                <h1 className={titleText}>{title}</h1>
                <p className={subtitleText}>{subtitle}</p>
                {description && <Markdown className={contentText}>{description}</Markdown>}
                {hasMedia && <Image className={image} media={media} />}
            </div>
        </Section>
    );
};

export default ArticlePreview;
